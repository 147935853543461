<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <data-view-sidebar
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
    />

    <vs-table
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="products"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <!-- ACTION - DROPDOWN -->
          <div v-if="$acl.not.check('admin')">
            Showing items for
            {{ currentBranch ? currentBranch.branch_name : 'N/A' }} branch
            {{ getMyBranch() }}
          </div>
          <vs-dropdown
            vs-trigger-click
            class="dd-actions cursor-pointer mr-4 mb-4"
            v-if="$acl.check('admin')"
          >
            <div
              class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
            >
              <span class="mr-2">
                {{
                  currentBranch == null
                    ? 'All Branches'
                    : 'Branch: ' + currentBranch.branch_name
                }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="changeBranch(null)">
                <span class="flex items-center">
                  <span>All Branches</span>
                </span>
              </vs-dropdown-item>
              <template>
                <vs-dropdown-item
                  @click="changeBranch(branch)"
                  v-for="(branch, index) in branchOptions"
                  v-bind:key="index"
                >
                  <span class="flex items-center">
                    <!-- <feather-icon
                    icon="TrashIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  /> -->
                    <span>{{ branch.branch_name }}</span>
                  </span>
                </vs-dropdown-item>
              </template>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- ADD NEW -->
          <div
            v-if="$acl.check('admin')"
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-primary border border-solid border-primary"
            @click="addNewData"
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Add New</span>
          </div>
          <div
            v-if="
              currentBranch !== null && $acl.check('admin') && !!win.ipcRenderer
            "
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-primary border border-solid border-primary"
            @click="printStock"
          >
            <feather-icon icon="FileTextIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Print Stock</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                products.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : products.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 200">
              <span>200</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="product_code">Code</vs-th>
        <vs-th sort-key="product_name">Name</vs-th>
        <vs-th sort-key="stock">Stock</vs-th>
        <!-- <vs-th sort-key="category">Category</vs-th> -->
        <vs-th sort-key="cost_price" v-if="$acl.check('admin')"
          >Cost Price</vs-th
        >
        <vs-th sort-key="wholesale_price">Wholesale Price</vs-th>
        <vs-th sort-key="retail_price">Retail Price</vs-th>
        <vs-th v-if="$acl.check('admin')">Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-code font-medium truncate">
                {{ tr.product_code }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.product_name }}
              </p>
            </vs-td>

            <vs-td>
              <template v-if="currentBranch === null && $acl.check('admin')">
                <template v-if="tr.version == '2'">
                  <vs-dropdown v-if="typeof tr.stockv2 !== 'undefined'">
                    <a href="#" class="flex items-center"
                      >View Stock
                      <i class="material-icons">expand_more</i>
                    </a>
                    <vs-dropdown-menu>
                      <vs-dropdown-item>
                        Stock information about this item in available
                        branches:-
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        v-for="branch in getBranchesv2(tr.stockv2)"
                        v-bind:key="branch._id"
                        divider
                        @click="changeBranch(branch.d)"
                      >
                        <span> {{ branch.b }}: {{ branch.stock }} </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                  <vx-tooltip
                    v-else
                    position="left"
                    text="This item does not have valid stock data"
                  >
                    <span>Stock Unavailable</span>
                  </vx-tooltip>
                </template>
                <template v-else>
                  <vs-dropdown v-if="typeof tr.stock === 'object'">
                    <a href="#" class="flex items-center"
                      >View Stock
                      <i class="material-icons">expand_more</i>
                    </a>
                    <vs-dropdown-menu>
                      <vs-dropdown-item>
                        Stock information about this item in available
                        branches:-
                      </vs-dropdown-item>
                      <vs-dropdown-item
                        v-for="branch in getBranches(tr.stock)"
                        v-bind:key="branch._id"
                        divider
                        @click="changeBranch(branch.d)"
                      >
                        <span> {{ branch.b }}: {{ branch.stock }} </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item divider>
                        Total : {{ getproducttotal(tr.stock) }}
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                  <vx-tooltip
                    v-else
                    position="left"
                    text="This item does not have valid stock data"
                  >
                    <span>Stock Unavailable</span>
                  </vx-tooltip>
                </template>
              </template>
              <template v-else>
                <div v-if="typeof currentBranch === 'object'">
                  <div v-if="tr.version == '2'">
                    {{ getStock(tr) }}
                  </div>

                  <div v-else>
                    {{
                      tr.stock && currentBranch._id in tr.stock
                        ? tr.stock[currentBranch._id]
                        : 'Stock Unavailable'
                    }}
                  </div>
                </div>
                <div v-else>N/A</div>
              </template>
            </vs-td>

            <vs-td v-if="$acl.check('admin')">
              <p class="product-price">
                {{
                  Number(tr.cost_price).toLocaleString('en-KE', {
                    style: 'currency',
                    currency: $root.businessData.data.currency,
                  })
                }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-price">
                {{
                  Number(tr.wholesale_price || tr.retail_price).toLocaleString(
                    'en-KE',
                    {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    },
                  )
                }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-price">
                {{
                  Number(tr.retail_price).toLocaleString('en-KE', {
                    style: 'currency',
                    currency: $root.businessData.data.currency,
                  })
                }}
              </p>
            </vs-td>

            <vs-td class="whitespace-no-wrap" v-if="$acl.check('admin')">
              <!-- <feather-icon
                icon="EditIcon"
                svgClasses="w-8 h-5 hover:text-primary stroke-current"
                @click.stop="
                  () => {
                    stockAdjustmentPopup = true;
                  }
                "
              /> -->
              <feather-icon
                icon="EditIcon"
                svgClasses="w-8 h-5 hover:text-primary stroke-current"
                @click.stop="editData(tr)"
              />
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-8 h-5 hover:text-danger stroke-current"
                class="ml-4"
                @click.stop="deleteData(tr)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup title="Stock Adjustment" :active.sync="stockAdjustmentPopup">
      <div class="vx-row">
        <div v-if="this.$acl.check('admin')" class="vx-row w-full ml-4 mr-4">
          <!-- <vs-divider>Database</vs-divider> -->
          <div class="vx-col w-full mt-2">
            <span class="text-sm">
              To create a new database and migrate, please contact our Support
              Team.</span
            >
          </div>
          <div class="vx-col md:w-1/3 w-full mt-5">
            <vs-input
              label="Remote DB Url"
              class="w-full"
              name="remote_url"
              v-validate="{ required: true }"
            />
            <span class="text-danger">{{ errors.first('remote_url') }}</span>
          </div>
        </div>

        <div
          v-if="this.$acl.check('admin')"
          @click="() => {}"
          class="w-full ml-6 mr-6 mt-6 btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-white bg-primary"
        >
          <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base text-white">Update</span>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import idb from '@/db/idb';
import DataViewSidebar from './DataViewSidebar.vue';

export default {
  components: {
    DataViewSidebar,
  },
  data() {
    return {
      stockAdjustmentPopup: false,
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      currentBranch: null,

      win: window,
    };
  },
  pouch: {
    productData() {
      return {
        database: 'express',
        selector: {
          type: 'product',
        },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    stockData() {
      return {
        database: 'express',
        selector: {
          type: 'stock',
          // branch_id: this.currentBranch || undefined,
        },
      };
    },
    branchData() {
      return {
        database: 'express',
        selector: { type: 'branch' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    products() {
      var p = this.productData ? this.productData : [];
      var sd = this.stockData ? this.stockData : [];
      var f = [];
      if (this.currentBranch != null) {
        for (let i = 0; i < p.length; i++) {
          const e = p[i];
          if (typeof e.stock === 'object') {
            var c = Object.keys(e.stock).includes(this.currentBranch._id);
            if (c == true) {
              f.push(e);
            }
          } else if (typeof e.stock === 'undefined') {
            // const stock = sd.find((e) => true);
            // e.stockv2 = stock;
            f.push(e);
          } else {
            // check if stock data has item
          }
        }
      } else {
        f = p;
      }

      for (let i = 0; i < f.length; i++) {
        f[i].stockv2 = sd.filter((e) => e.item_id === f[i]._id) || [];
      }

      return f;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.products.length;
    },
    branchOptions() {
      if (this.branchData) {
        if (this.branchData.length == 1) {
          this.changeBranch(this.branchData[0]);
        }
        return this.branchData;
      } else {
        return [];
      }
    },
  },
  methods: {
    getStock(item) {
      if (!item || !Object(item).hasOwnProperty('stockv2')) {
        return 'Stock Unavailable (v2) 001';
      }

      const branchStock = item.stockv2.find(
        (e) => e.branch_id === this.currentBranch._id,
      );

      return branchStock ? Number(branchStock.stock) : 'Stock Unavailable (v2)';
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
    getDate(x) {
      return this.$moment(x).format('DD MMM YYYY hh:mm:ss');
    },
    showMessage(icon, color, title, message) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: icon,
        color: color,
      });
    },
    printStock() {
      this.showMessage(
        'icon-check-circle',
        'warning',
        'Processing Request',
        'Please wait as we process this request',
      );
      window.ipcRenderer.send('stockReceipt', {
        business: this.$root.businessData.data,
        branch: this.currentBranch,
        products: this.products,
        date: this.getDate(Date.now()),
        user: this.$session.get('user'),
      });
      this.showMessage(
        'icon-check-circle',
        'success',
        'Request Processed',
        'Check your printer, the receipt has been generated and sent to the printer',
      );
    },
    changeBranch(b) {
      this.currentBranch = b;
    },
    getBranches(c) {
      var a = [];
      for (let i in c) {
        if (c.hasOwnProperty(i)) {
          var branch = this.branchOptions.find((e) => e._id == i);
          if (branch != undefined) {
            a.push({
              d: branch,
              b: branch.branch_name,
              stock: c[i],
            });
          }
        }
      }
      return a;
    },
    getBranchesv2(c) {
      var a = [];
      for (let i = 0; i < c.length; i++) {
        const branchStock = c[i];
        var branch = this.branchOptions.find(
          (e) => e._id == branchStock.branch_id,
        );
        if (branch != undefined) {
          a.push({
            d: branch,
            b: branch.branch_name,
            stock: branchStock.stock,
          });
        }
      }
      return a;
    },
    getproducttotal(c) {
      var t = 0;
      for (let i in c) {
        if (c.hasOwnProperty(i)) {
          t = t + parseInt(c[i]);
        }
      }
      return t;
    },
    // getCategory(id) {
    //     return this.$store.state.products.categories.find((i) => i.id == id)['category']
    // },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    deleteData(product) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Delete Item`,
        text: `You are about to delete an item ${product.product_name}. Are you sure you want to proceed?`,
        accept: () => {
          this.$store
            .dispatch('products/removeProduct', product)
            .catch((err) => {
              console.error(err);
            });
        },
      });
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    getOrderStatusColor(status) {
      if (status == 'on_hold') return 'warning';
      if (status == 'delivered') return 'success';
      if (status == 'canceled') return 'danger';
      return 'primary';
    },
    getStockColor(num) {
      if (num > 50) return 'success';
      if (num >= 10) return 'warning';
      if (num < 30) return 'danger';
      return 'primary';
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    getMyBranch() {
      this.currentBranch = this.branchOptions.find(
        (e) => e._id === this.$session.get('user').branch,
      );
    },
  },
  async created() {},
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
